import { css } from "@emotion/core"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Spring } from "react-spring/renderprops"
import React, { useRef } from "react"
import Layout from "../components/layout"
import { fonts, container, mediaQueries, weights } from "../styles"
import FullWidthSection from "../components/FullWidthSection"
import { useHasBeenVisible } from "../hooks/useVisibility"

const Capability = ({ imageSrc, imageAlt, content, index, id }) => {
  const nodeRef = useRef()
  const isVisible = useHasBeenVisible(nodeRef)

  return (
    <FullWidthSection
      ref={nodeRef}
      height="0"
      padding="0"
      textAlign="left"
      css={css`
        &:first-of-type {
          margin-top: 20px;
        }
      `}
    >
      <div id={id} css={container.medium}>
        <div
          css={css`
            margin-bottom: 90px;

            ${mediaQueries.phoneLarge} {
              display: flex;
              justify-content: space-between;
              flex-direction: ${index % 2 ? "row-reverse" : "row"};
              align-items: center;
              margin-bottom: 170px;
            }

            h2 {
              font-size: 33px;
              font-weight: ${weights.bold};
            }

            p {
              font-weight: ${weights.light};
            }

            ul {
              margin: 0;
              padding: 0 0 0 0.3rem;
              font-size: 18px;

              li {
                font-family: ${fonts.sans};
                font-weight: ${weights.bold};
                font-variant-caps: all-small-caps;
                letter-spacing: 1px;
                list-style: none;
              }

              li:before {
                content: "- ";
              }
            }
          `}
        >
          <Spring
            delay={0}
            to={{
              transform: isVisible ? "translateY(0)" : "translateY(200px)",
              opacity: isVisible ? "1" : "0",
            }}
          >
            {({ transform, opacity }) => (
              <Img
                fluid={imageSrc}
                alt={imageAlt}
                style={{ transform, opacity }}
                css={css`
                  width: 100%;
                  margin-bottom: 20px;

                  ${mediaQueries.phoneLarge} {
                    flex: 0 0 39%;
                    width: 39%;
                    margin-bottom: 0;

                    > div {
                      padding-bottom: "70% !important";
                    }
                  }
                `}
              />
            )}
          </Spring>

          <div
            css={css`
              position: relative;

              ${mediaQueries.phoneLarge} {
                flex: 0 0 50%;
                width: 50%;
              }
            `}
          >
            {content}
          </div>
        </div>
      </div>
    </FullWidthSection>
  )
}

const SolarPage = ({ data }) => {
  return (
    <Layout
      headerData={{
        title: "MaxPlanZ",
        height: "500px",
        mobileMinHeight: "470px",
        titleMarginBottom: "70px",
      }}
    >
      <Img
        fluid={data.solarImage.childImageSharp.fluid}
        css={css`
          margin-left: 20px;
          margin-right: 20px;
          margin-top: calc(-25.5% + 15px);
          max-width: 980px;

          ${mediaQueries.xs} {
            margin-top: -165px;
          }

          ${mediaQueries.phoneLarge} {
            margin-left: auto;
            margin-right: auto;
          }
        `}
      />
      <div
        css={css`
          ${container.min}
          padding-top: 30px;
          margin-bottom: 60px;
          font-weight: ${weights.light};

          ${mediaQueries.phoneLarge} {
            padding-left: 0;
            padding-right: 0;
            padding-top: 80px;
          }
        `}
      >
        <p>
          MaxPlanZは時間単位、過積載条件での太陽光発電シミュレーションシステムです。MaxPlanZは弊社の日単位太陽光発電シミュレーションシステム（SolarSimu）の後継商品です。
        </p>
        <p>
          SolarSimuは2015年の商品化以来、150以上のお客様から好評を得、今も現場で愛用されています。SolarSimuの経験を活かし、更に機能を強化したMaxPlanZを発売させていただきます。
        </p>
        <h3>MaxPlanZの機能</h3>
        <ul>
          <li>時間単位発電シミュレーション条件の入力</li>
          <li>時間単位発電シミュレーション条件の入力</li>
          <li>過積載別収支比較</li>
          <li>シミュレーション結果の検索・管理機能</li>
          <li>四季別、四季代表日・代表月の発電量グラフ出力</li>
          <li>過積載収支シミュレーション</li>
          <li>係数設定</li>
        </ul>
        <h3>MaxPlanZの特徴</h3>
      </div>
      <Capability
        id="one"
        imageSrc={data.solarOneImage.childImageSharp.fluid}
        imageAlt="過積載対応"
        content={
          <>
            <h4>1.過積載対応</h4>
            <p>
              任意の過積載率を指定して、時間単位の定格発電量と過積載により発電量増量のシミュレーションを行い、発電量を計算する。総発電量及び設備の利用率の大幅UPがはかれる。
            </p>
            <p>図はMaxPlanZのシミュレーション結果です。</p>
          </>
        }
        index={0}
      />
      <Capability
        id="two"
        imageSrc={data.solarTwoImage.childImageSharp.fluid}
        imageAlt="日本国内最も権威のある日射量データベースを活用"
        content={
          <>
            <h4>2.日本国内最も権威のある日射量データベースを活用</h4>
            <p>
              日本全国837基準地、任意の方位角、設置角度でのシミュレーションの計算ができる。
            </p>
            <p>
              図はMaxPlanZが実際出力した「四季代表日時間別発電量出力グラフ」です。
            </p>
            <p>
              「四季代表月時間別発電量出力グラフ」、「四季時間別発電量出力グラフ」と「年間月別発電量出力グラフ」の出力もできる。パワーポイントに貼り付けてすぐ営業活動に活用できる。PDFへ出力すると直接のメール送付が可能です。
            </p>
          </>
        }
        index={1}
      />
      <Capability
        id="three"
        imageSrc={data.solarThreeImage.childImageSharp.fluid}
        imageAlt="全ての出力ファイルはExcel形式出力"
        content={
          <>
            <h4>3.全ての出力ファイルはExcel形式出力</h4>
            <p>
              出力はExcel形式なので、営業用に再加工が非常に便利です。Excelの内容をパワーポインに貼り付ければ、説得力のあるプレゼンテーション営業資料が出来上がる。ExcelをPDFへ変換すれば、メールで直接送付が可能です。
            </p>
            <p>
              利用者から営業資料作成時間が90%削減の報告を受けている。今まで手作業で6時間をかかったプレゼンテーション資料作成作業がわずかの30分で完成。
            </p>
          </>
        }
        index={2}
      />
      <Capability
        id="four"
        imageSrc={data.solarFourImage.childImageSharp.fluid}
        imageAlt="発電係数の自由調整"
        content={
          <>
            <h4>4.発電係数の自由調整</h4>
            <p>
              営業活動の目的に合わせて、発電出力係数の調整が可能です。例：提案時に高めの発電出力係数を、収支計画時に低めの発電出力係数を設定することができる。
            </p>
            <p>
              - 高め係数の場合： 191kWh
              <br /> - 標準係数の場合： 180kWh
              <br /> - 低め係数の場合： 170kWh
            </p>
            <p>
              標準係数の場合、非常に精確の発電シミュレーションができるが、年によって日射量は一定ではないので、発電係数の調整は合理の範囲内で行ってください。
            </p>
          </>
        }
        index={3}
      />
      <Capability
        id="five"
        imageSrc={data.solarFiveImage.childImageSharp.fluid}
        imageAlt="強力な収支シミュレーション"
        content={
          <>
            <h4>5.強力な収支シミュレーション</h4>
            <p>
              投資要素〔パネル、ﾊﾟﾜｺﾝ、工事、ランニング費用〕と収益要素〔発電量、電力単価〕から10年～30年間の投資と回収プランのシミュレーションができる。
            </p>
            <p>収支分岐点と運行年毎に収益状況を表示する。</p>
            <p>
              収支分岐点：6年10ヶ月、パネルの劣化率：一年目2.5%、2年目以降0.6%
            </p>
          </>
        }
        index={4}
      />
      <Capability
        id="six"
        imageSrc={data.solarSixImage.childImageSharp.fluid}
        imageAlt="複数の過積載率の発電量を一覧で比較"
        content={
          <>
            <h4>6.複数の過積載率の発電量を一覧で比較</h4>
            <p>
              MaxPlanZが過積載率110％～300％の発電シミュレーション計算結果を一覧で表示できる。個別過積載率の発電量詳細を一覧から一発でシミュレーションすることができる。
            </p>
          </>
        }
        index={5}
      />
      <Capability
        id="seven"
        imageSrc={data.solarSevenImage.childImageSharp.fluid}
        imageAlt="その他便利な機能"
        content={
          <>
            <h4>7.その他便利な機能</h4>
            <ul>
              <li>
                完全機能の無料試用版を配布してます。試用期間１ヵ月で作成したシミュレーション結果をそのまま本使用へ移行することが可能です。
              </li>
              <li>自社ロゴを出力ファイルに出力できる。</li>
              <li>
                特別要望のカスタマイズ対応。お客様の特別の要望について、基本的に有料で、カスタマイズ対応が可能です。
              </li>
            </ul>
          </>
        }
        index={6}
      />
    </Layout>
  )
}

export const query = graphql`
  query SolarQuery {
    solarImage: file(relativePath: { eq: "solar.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarOneImage: file(relativePath: { eq: "solar-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarTwoImage: file(relativePath: { eq: "solar-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarThreeImage: file(relativePath: { eq: "solar-3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarFourImage: file(relativePath: { eq: "solar-4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarFiveImage: file(relativePath: { eq: "solar-5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarSixImage: file(relativePath: { eq: "solar-6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solarSevenImage: file(relativePath: { eq: "solar-7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default SolarPage
